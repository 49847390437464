define("@ember-intl/cp-validations/validators/messages", ["exports", "ember-cp-validations/validators/messages", "@ember/service", "@ember/debug", "@ember/application", "@ember/utils"], function (_exports, _messages, _service, _debug, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _messages.default.extend({
    intl: (0, _service.inject)(),
    prefix: 'errors',
    init() {
      this._super(...arguments);
      let owner = (0, _application.getOwner)(this);
      if (owner) {
        this._config = owner.resolveRegistration('config:environment') || {};
      }
    },
    _warn(msg, test, meta) {
      if (this._config?.intl_cp_validations?.suppressWarnings) {
        return;
      }
      (false && (0, _debug.warn)(msg, test, meta));
    },
    getDescriptionFor(attribute, options = {}) {
      let intl = this.intl;
      let key = `${this.prefix}.description`;
      let foundCustom;
      if (!(0, _utils.isEmpty)(options.descriptionKey)) {
        key = options.descriptionKey;
        foundCustom = true;
      } else if (!(0, _utils.isEmpty)(options.description)) {
        return options.description;
      }
      if (intl) {
        if (intl.exists(key)) {
          return intl.t(key, options);
        } else if (foundCustom) {
          this._warn(`Custom descriptionKey '${key}' provided but does not exist in intl translations.`, false, {
            id: 'ember-intl-cp-validations-missing-custom-key'
          });
        }
      }
      return this._super(...arguments);
    },
    getMessageFor(type, options = {}) {
      let key = options.messageKey || `${this.prefix}.${type}`;
      let intl = this.intl;
      if (intl && intl.exists(key)) {
        return this.formatMessage(intl.t(key, options));
      }
      this._warn(`[@ember-intl/cp-validations] Missing translation for validation key: ${key}\nhttps://adopted-ember-addons.github.io/ember-cp-validations/docs/messages/index.html`, false, {
        id: 'ember-intl-cp-validations-missing-translation'
      });
      return this._super(...arguments);
    }
  });
});