define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   */
  var faArrowAltRight = {
    prefix: 'fal',
    iconName: 'arrow-alt-right',
    icon: [448, 512, [], "f356", "M32 212.57A20.57 20.57 0 0 1 52.57 192H224V75a8 8 0 0 1 13.66-5.66L410 241.44a20.56 20.56 0 0 1 0 29.11L237.66 442.63A8 8 0 0 1 224 437V320H52.57A20.57 20.57 0 0 1 32 299.42v-86.85m-32 0v86.85A52.63 52.63 0 0 0 52.57 352H192v85c0 35.51 43 53.5 68.27 28.3l172.3-172.08a52.55 52.55 0 0 0 0-74.4L260.27 46.71C235.15 21.62 192 39.36 192 75v85H52.57A52.63 52.63 0 0 0 0 212.57z"]
  };
  var faBook = {
    prefix: 'fal',
    iconName: 'book',
    icon: [448, 512, [], "f02d", "M356 160H188c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12zm12 52v-8c0-6.6-5.4-12-12-12H188c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12zm64.7 268h3.3c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H80c-44.2 0-80-35.8-80-80V80C0 35.8 35.8 0 80 0h344c13.3 0 24 10.7 24 24v368c0 10-6.2 18.6-14.9 22.2-3.6 16.1-4.4 45.6-.4 65.8zM128 384h288V32H128v352zm-96 16c13.4-10 30-16 48-16h16V32H80c-26.5 0-48 21.5-48 48v320zm372.3 80c-3.1-20.4-2.9-45.2 0-64H80c-64 0-64 64 0 64h324.3z"]
  };
  var faCalendar = {
    prefix: 'fal',
    iconName: 'calendar',
    icon: [448, 512, [], "f133", "M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"]
  };
  var faCamera = {
    prefix: 'fal',
    iconName: 'camera',
    icon: [512, 512, [], "f030", "M324.3 64c3.3 0 6.3 2.1 7.5 5.2l22.1 58.8H464c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h110.2l20.1-53.6c2.3-6.2 8.3-10.4 15-10.4h131m0-32h-131c-20 0-37.9 12.4-44.9 31.1L136 96H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48h-88l-14.3-38c-5.8-15.7-20.7-26-37.4-26zM256 408c-66.2 0-120-53.8-120-120s53.8-120 120-120 120 53.8 120 120-53.8 120-120 120zm0-208c-48.5 0-88 39.5-88 88s39.5 88 88 88 88-39.5 88-88-39.5-88-88-88z"]
  };
  var faCapsules = {
    prefix: 'fal',
    iconName: 'capsules',
    icon: [544, 512, [], "f46b", "M529 296.8L417.5 103.7c-30.8-53.2-99-71.5-152.3-40.8-21.2 12.2-36.5 30.5-45.8 51.3C206.3 67 163.4 32 112 32 50.2 32 0 82.2 0 144v224c0 61.8 50.2 112 112 112s112-50.2 112-112V214.2c.2.3.2.6.4.9l111.5 193.1c20.6 35.7 58.2 55.7 96.8 55.7 18.9 0 38-4.8 55.5-14.9 25.8-14.9 44.2-38.9 51.9-67.7 7.8-28.7 3.8-58.7-11.1-84.5zM112 64c44.1 0 80 35.9 80 80v112H32V144c0-44.1 35.9-80 80-80zm0 384c-44.1 0-80-35.9-80-80v-80h160v80c0 44.1-35.9 80-80 80zM281.2 90.5c40.4-23.3 88.2-6.5 108.8 29.1l47.6 82.4-137.9 79.6-47.7-82.3c-21.9-38.1-8.9-86.9 29.2-108.8zm228.2 282.7c-5.5 20.5-18.7 37.7-37.1 48.4-38 21.9-86.8 8.8-108.8-29.1l-47.9-83 137.9-79.6 47.9 83c10.7 18.2 13.5 39.7 8 60.3z"]
  };
  var faCircle = {
    prefix: 'fal',
    iconName: 'circle',
    icon: [512, 512, [], "f111", "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z"]
  };
  var faDownload = {
    prefix: 'fal',
    iconName: 'download',
    icon: [512, 512, [], "f019", "M452 432c0 11-9 20-20 20s-20-9-20-20 9-20 20-20 20 9 20 20zm-84-20c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm144-48v104c0 24.3-19.7 44-44 44H44c-24.3 0-44-19.7-44-44V364c0-24.3 19.7-44 44-44h99.4L87 263.6c-25.2-25.2-7.3-68.3 28.3-68.3H168V40c0-22.1 17.9-40 40-40h96c22.1 0 40 17.9 40 40v155.3h52.7c35.6 0 53.4 43.1 28.3 68.3L368.6 320H468c24.3 0 44 19.7 44 44zm-261.7 17.7c3.1 3.1 8.2 3.1 11.3 0L402.3 241c5-5 1.5-13.7-5.7-13.7H312V40c0-4.4-3.6-8-8-8h-96c-4.4 0-8 3.6-8 8v187.3h-84.7c-7.1 0-10.7 8.6-5.7 13.7l140.7 140.7zM480 364c0-6.6-5.4-12-12-12H336.6l-52.3 52.3c-15.6 15.6-41 15.6-56.6 0L175.4 352H44c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12V364z"]
  };
  var faEllipsisHAlt = {
    prefix: 'fal',
    iconName: 'ellipsis-h-alt',
    icon: [512, 512, [], "f39b", "M256 184c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm0 112c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zm176-112c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm0 112c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zM80 184c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm0 112c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40z"]
  };
  var faEnvelope = {
    prefix: 'fal',
    iconName: 'envelope',
    icon: [512, 512, [], "f0e0", "M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"]
  };
  var faEuroSign = {
    prefix: 'fal',
    iconName: 'euro-sign',
    icon: [320, 512, [], "f153", "M303.625 444.131c-1.543-6.481-8.063-10.445-14.538-8.874-10.014 2.43-25.689 5.304-43.827 5.304-80.726 0-141.733-46.614-160.837-120.561h155.241a12 12 0 0 0 11.784-9.731l1.541-8c1.425-7.402-4.246-14.269-11.784-14.269H77.646c-1.849-20.951-1.849-43.664.616-64h178.657a12 12 0 0 0 11.784-9.731l1.541-8c1.425-7.402-4.246-14.269-11.784-14.269H85.04c20.951-70.25 80.111-120.561 159.604-120.561 14.725 0 28.452 2.194 37.551 4.086 6.282 1.306 12.47-2.581 14.05-8.799l3.93-15.475c1.689-6.652-2.529-13.383-9.262-14.718C280.423 34.452 264.068 32 245.26 32 143.582 32 63.472 100.181 39.439 192H12c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12h21.893c-2.466 17.87-1.849 49.827-.617 64H12c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12h26.207c21.568 96.749 101.678 160 207.053 160 22.511 0 41.595-3.426 53.081-6.106 6.47-1.51 10.465-7.999 8.926-14.462l-3.642-15.301z"]
  };
  var faExpand = {
    prefix: 'fal',
    iconName: 'expand',
    icon: [448, 512, [], "f065", "M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H32v116c0 6.6-5.4 12-12 12h-8c-6.6 0-12-5.4-12-12zM300 32h124c13.3 0 24 10.7 24 24v124c0 6.6-5.4 12-12 12h-8c-6.6 0-12-5.4-12-12V64H300c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12zm148 300v124c0 13.3-10.7 24-24 24H300c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h116V332c0-6.6 5.4-12 12-12h8c6.6 0 12 5.4 12 12zM148 480H24c-13.3 0-24-10.7-24-24V332c0-6.6 5.4-12 12-12h8c6.6 0 12 5.4 12 12v116h116c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12z"]
  };
  var faFileAlt = {
    prefix: 'fal',
    iconName: 'file-alt',
    icon: [384, 512, [], "f15c", "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z"]
  };
  var faFileMedical = {
    prefix: 'fal',
    iconName: 'file-medical',
    icon: [384, 512, [], "f477", "M224 232c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v56h-56c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h56v56c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-56h56c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8h-56v-56zM369.9 98L286 14.1C277 5.1 264.8 0 252.1 0H48C21.5.1 0 21.6 0 48.1V464c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V132c0-12.7-5.1-25-14.1-34zM256 32.6c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9c2.1 2.1 3.5 4.6 4.2 7.4H256V32.6zM352 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V48.1C32 39.3 39.2 32 48 32h176v104.1c0 13.3 10.7 23.9 24 23.9h104v304z"]
  };
  var faMicroscope = {
    prefix: 'fal',
    iconName: 'microscope',
    icon: [512, 512, [], "f610", "M456 416h-40.99C454.22 386.81 480 340.52 480 288c0-88.22-71.78-160-160-160V64c0-17.67-14.33-32-32-32V16c0-8.84-7.16-16-16-16h-96c-8.84 0-16 7.16-16 16v16c-17.67 0-32 14.33-32 32v224c0 17.67 14.33 32 32 32v48c0 8.84 7.16 16 16 16h96c8.84 0 16-7.16 16-16v-48c17.67 0 32-14.33 32-32V160c70.59 0 128 57.42 128 128s-57.41 128-128 128H56c-30.88 0-56 25.12-56 56 0 22.06 17.94 40 40 40h432c22.06 0 40-17.94 40-40 0-30.88-25.12-56-56-56zm-200-64h-64v-32h64v32zm32-64H160V64h32V32h64v32h32v224zm184 192H40c-4.42 0-8-3.58-8-8 0-13.26 10.75-24 24-24h400c13.25 0 24 10.74 24 24 0 4.42-3.58 8-8 8z"]
  };
  var faPaperclip = {
    prefix: 'fal',
    iconName: 'paperclip',
    icon: [512, 512, [], "f0c6", "M149.106 512c-33.076 0-66.153-12.59-91.333-37.771-50.364-50.361-50.364-132.305-.002-182.665L319.842 29.498c39.331-39.331 103.328-39.331 142.66 0 39.331 39.332 39.331 103.327 0 142.657l-222.63 222.626c-28.297 28.301-74.347 28.303-102.65 0-28.3-28.301-28.3-74.349 0-102.649l170.301-170.298c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971l-170.3 170.297c-15.821 15.821-15.821 41.563.001 57.385 15.821 15.82 41.564 15.82 57.385 0l222.63-222.626c26.851-26.851 26.851-70.541 0-97.394-26.855-26.851-70.544-26.849-97.395 0L80.404 314.196c-37.882 37.882-37.882 99.519 0 137.401 37.884 37.881 99.523 37.882 137.404.001l217.743-217.739c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971L240.44 474.229C215.26 499.41 182.183 512 149.106 512z"]
  };
  var faPhone = {
    prefix: 'fal',
    iconName: 'phone',
    icon: [512, 512, [], "f095", "M487.8 24.1L387 .8c-14.7-3.4-29.8 4.2-35.8 18.1l-46.5 108.5c-5.5 12.7-1.8 27.7 8.9 36.5l53.9 44.1c-34 69.2-90.3 125.6-159.6 159.6l-44.1-53.9c-8.8-10.7-23.8-14.4-36.5-8.9L18.9 351.3C5 357.3-2.6 372.3.8 387L24 487.7C27.3 502 39.9 512 54.5 512 306.7 512 512 307.8 512 54.5c0-14.6-10-27.2-24.2-30.4zM55.1 480l-23-99.6 107.4-46 59.5 72.8c103.6-48.6 159.7-104.9 208.1-208.1l-72.8-59.5 46-107.4 99.6 23C479.7 289.7 289.6 479.7 55.1 480z"]
  };
  var faPrescriptionBottle = {
    prefix: 'fal',
    iconName: 'prescription-bottle',
    icon: [384, 512, [], "f485", "M360 0H24C10.8 0 0 10.8 0 24v80c0 13.2 10.8 24 24 24h8v352c0 17.6 14.4 32 32 32h256c17.6 0 32-14.4 32-32V128h8c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24zm-40 480H64v-64h88c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H64v-64h88c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H64v-64h88c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H64v-64h256v352zm32-384H32V32h320v64z"]
  };
  var faReceipt = {
    prefix: 'fal',
    iconName: 'receipt',
    icon: [448, 512, [], "f543", "M344 240H104c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0 96H104c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM418.1 0c-5.8 0-11.8 1.8-17.3 5.7L357.3 37 318.7 9.2c-8.4-6-18.2-9.1-28.1-9.1-9.8 0-19.6 3-28 9.1L224 37 185.4 9.2C177 3.2 167.1.1 157.3.1s-19.6 3-28 9.1L90.7 37 47.2 5.7C41.8 1.8 35.8 0 29.9 0 14.4.1 0 12.3 0 29.9v452.3C0 499.5 14.3 512 29.9 512c5.8 0 11.8-1.8 17.3-5.7L90.7 475l38.6 27.8c8.4 6 18.2 9.1 28.1 9.1 9.8 0 19.6-3 28-9.1L224 475l38.6 27.8c8.4 6 18.3 9.1 28.1 9.1s19.6-3 28-9.1l38.6-27.8 43.5 31.3c5.4 3.9 11.4 5.7 17.3 5.7 15.5 0 29.8-12.2 29.8-29.8V29.9C448 12.5 433.7 0 418.1 0zM416 477.8L376 449l-18.7-13.5-18.7 13.5-38.6 27.8c-2.8 2-6 3-9.3 3-3.4 0-6.6-1.1-9.4-3.1L242.7 449 224 435.5 205.3 449l-38.6 27.8c-2.8 2-6 3-9.4 3-3.4 0-6.6-1.1-9.4-3.1L109.3 449l-18.7-13.5L72 449l-40 29.4V34.2L72 63l18.7 13.5L109.4 63 148 35.2c2.8-2 6-3 9.3-3 3.4 0 6.6 1.1 9.4 3.1L205.3 63 224 76.5 242.7 63l38.6-27.8c2.8-2 6-3 9.4-3 3.4 0 6.6 1.1 9.4 3.1L338.7 63l18.7 13.5L376 63l40-28.8v443.6zM344 144H104c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8z"]
  };
  var faRedo = {
    prefix: 'fal',
    iconName: 'redo',
    icon: [512, 512, [], "f01e", "M492 8h-10c-6.627 0-12 5.373-12 12v110.625C426.804 57.047 346.761 7.715 255.207 8.001 118.82 8.428 7.787 120.009 8 256.396 8.214 393.181 119.166 504 256 504c63.926 0 122.202-24.187 166.178-63.908 5.113-4.618 5.354-12.561.482-17.433l-7.069-7.069c-4.503-4.503-11.749-4.714-16.482-.454C361.218 449.238 311.065 470 256 470c-117.744 0-214-95.331-214-214 0-117.744 95.331-214 214-214 82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12z"]
  };
  var faScalpel = {
    prefix: 'fal',
    iconName: 'scalpel',
    icon: [544, 512, [], "f61d", "M511.45 13.17C498.87 4.19 484.31 0 469.7 0c-23.06 0-46.27 10.44-62.13 28.97L183.68 290.6c-5.79 6.77-4.9 15.34-.5 21.57L0 512h.37c101.37 0 198.37-34.12 272.96-94.68.23-.19.46-.37.69-.56 30.39-24.9 47.16-59.96 52.22-96.75h17.57c10.41 0 20.3-4.55 27.08-12.47l154.47-180.49c29.31-34.23 23.6-87.13-13.91-113.88zm-258.28 379.3c-49.49 40.19-109.26 67.52-173.46 79.93L219.41 320h75.21c-4.94 28.79-19.33 54.35-41.45 72.47zm247.88-286.23L346.58 286.73c-.69.81-1.7 1.28-2.77 1.28H228.02L431.88 49.78C441.41 38.64 455.54 32 469.7 32c8.54 0 16.54 2.5 23.17 7.22 10.54 7.52 17.22 18.75 18.79 31.62 1.56 12.79-2.3 25.7-10.61 35.4z"]
  };
  var faStethoscope = {
    prefix: 'fal',
    iconName: 'stethoscope',
    icon: [512, 512, [], "f0f1", "M430.1 96.5c-43.6 1-78.7 37.5-78.1 81.1.5 38.2 27.8 69.9 64 77.2v105.6c0 65.9-57.4 119.5-128 119.5s-128-53.6-128-119.5v-8.9c71.9-8 128-69.1 128-143.1V25.8c0-5.5-3.7-10.3-9.1-11.6L223.5.4c-6.4-1.6-12.9 2.3-14.6 8.7l-1.9 7.8c-1.6 6.4 2.3 12.9 8.7 14.6l40.3 10v165.8c0 61.8-49.5 112.9-111.3 113.2-62.1.4-112.7-50-112.7-112v-167l40.2-10.1c6.4-1.6 10.3-8.1 8.7-14.6L79 9.1C77.4 2.7 70.9-1.2 64.4.4L9.1 14.2C3.7 15.5 0 20.3 0 25.8v182.6c0 74 56.1 135.1 128 143.1v8.9C128 444 199.8 512 288 512s160-68 160-151.5V254.9c36.5-7.4 64-39.7 64-78.4 0-44.9-36.9-81.1-81.9-80zm1.9 128c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm23.5-48c0 13-10.5 23.5-23.5 23.5s-23.5-10.5-23.5-23.5S419 153 432 153s23.5 10.5 23.5 23.5z"]
  };
  var faSyringe = {
    prefix: 'fal',
    iconName: 'syringe',
    icon: [512, 512, [], "f48e", "M509.7 81.5L430.5 2.3c-3.1-3.1-8.2-3.1-11.3 0l-11.3 11.3c-3.1 3.1-3.1 8.2 0 11.3l28.3 28.3-45.3 45.3-56.6-56.6-17-17c-3.1-3.1-8.2-3.1-11.3 0l-11.3 11.3c-3.1 3.1-3.1 8.2 0 11.3l17 17L84.5 291.7c-17.3 17.3-25.6 41.1-23 65.4l7.1 63.6L2.3 487c-3.1 3.1-3.1 8.2 0 11.3l11.3 11.3c3.1 3.1 8.2 3.1 11.3 0l66.3-66.3 63.6 7.1c23.9 2.6 47.9-5.4 65.4-23l227.2-227.2 17 17c3.1 3.1 8.2 3.1 11.3 0L487 206c3.1-3.1 3.1-8.2 0-11.3l-73.5-73.5 45.3-45.3 28.3 28.3c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.2 3.1-8.2 0-11.4zm-84.9 96.2L197.7 404.9c-10.4 10.4-24.6 15.4-39.2 13.8l-58.5-6.5-6.5-58.5c-1.6-14.6 3.4-28.9 13.8-39.2l23.5-23.5 39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6 45.3-45.3 39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6 45.3-45.3 39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3L289 132.4l45.3-45.3 90.5 90.6z"]
  };
  var faThumbtack = {
    prefix: 'fal',
    iconName: 'thumbtack',
    icon: [384, 512, [], "f08d", "M300.8 203.9L290.7 128H328c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24H56C42.8 0 32 10.8 32 24v80c0 13.2 10.8 24 24 24h37.3l-10.1 75.9C34.9 231.5 0 278.4 0 335.2c0 8.8 7.2 16 16 16h160V472c0 .7.1 1.3.2 1.9l8 32c2 8 13.5 8.1 15.5 0l8-32c.2-.6.2-1.3.2-1.9V351.2h160c8.8 0 16-7.2 16-16 .1-56.8-34.8-103.7-83.1-131.3zM33.3 319.2c6.8-42.9 39.6-76.4 79.5-94.5L128 96H64V32h256v64h-64l15.3 128.8c40 18.2 72.7 51.8 79.5 94.5H33.3z"]
  };
  var faTimesCircle = {
    prefix: 'fal',
    iconName: 'times-circle',
    icon: [512, 512, [], "f057", "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"]
  };
  var faUndo = {
    prefix: 'fal',
    iconName: 'undo',
    icon: [512, 512, [], "f0e2", "M20 8h10c6.627 0 12 5.373 12 12v110.625C85.196 57.047 165.239 7.715 256.793 8.001 393.18 8.428 504.213 120.009 504 256.396 503.786 393.181 392.834 504 256 504c-63.926 0-122.202-24.187-166.178-63.908-5.113-4.618-5.354-12.561-.482-17.433l7.069-7.069c4.503-4.503 11.749-4.714 16.482-.454C150.782 449.238 200.935 470 256 470c117.744 0 214-95.331 214-214 0-117.744-95.331-214-214-214-82.862 0-154.737 47.077-190.289 116H180c6.627 0 12 5.373 12 12v10c0 6.627-5.373 12-12 12H20c-6.627 0-12-5.373-12-12V20c0-6.627 5.373-12 12-12z"]
  };
  var faUserMd = {
    prefix: 'fal',
    iconName: 'user-md',
    icon: [448, 512, [], "f0f0", "M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96zm93.7 256.1c-33.8-1-44.3 15.9-93.7 15.9-49.3 0-59.8-16.9-93.6-15.9C58 290.2 0 349.5 0 422.4V504c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-81.6c0-54.3 42.6-98.4 96-101.8v81.7c-23.1 6.9-40 28.3-40 53.7 0 30.9 25.1 56 56 56s56-25.1 56-56c0-25.4-16.9-46.8-40-53.7v-76.9c20.8 6.8 42.2 10.5 64 10.5 21.8 0 43.2-3.7 64-10.5v68.8c-28.2 7.5-48 34.5-48 64.6V488c0 4.2 1.7 8.3 4.7 11.3l10.3 10.3c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3l-5.7-5.7V456c0-19.4 17.4-34.8 37.4-31.6 15.7 2.6 26.6 17.4 26.6 33.3v23.6l-5.7 5.7c-3.1 3.1-3.1 8.2 0 11.3l11.3 11.3c3.1 3.1 8.2 3.1 11.3 0l10.3-10.3c3-3 4.7-7.1 4.7-11.3v-32c0-29.7-20.5-54.5-48-61.6v-73.7c53.4 3.4 96 47.5 96 101.8V504c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-81.6c.2-72.9-57.8-132.2-130.1-134.3zM168 456c0 13.2-10.8 24-24 24s-24-10.8-24-24 10.8-24 24-24 24 10.8 24 24z"]
  };
  var faUsers = {
    prefix: 'fal',
    iconName: 'users',
    icon: [640, 512, [], "f0c0", "M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z"]
  };
  var faVideo = {
    prefix: 'fal',
    iconName: 'video',
    icon: [576, 512, [], "f03d", "M543.9 96c-6.2 0-12.5 1.8-18.2 5.7L416 171.6v-59.8c0-26.4-23.2-47.8-51.8-47.8H51.8C23.2 64 0 85.4 0 111.8v288.4C0 426.6 23.2 448 51.8 448h312.4c28.6 0 51.8-21.4 51.8-47.8v-59.8l109.6 69.9c5.7 4 12.1 5.7 18.2 5.7 16.6 0 32.1-13 32.1-31.5v-257c.1-18.5-15.4-31.5-32-31.5zM384 400.2c0 8.6-9.1 15.8-19.8 15.8H51.8c-10.7 0-19.8-7.2-19.8-15.8V111.8c0-8.6 9.1-15.8 19.8-15.8h312.4c10.7 0 19.8 7.2 19.8 15.8v288.4zm160-15.7l-1.2-1.3L416 302.4v-92.9L544 128v256.5z"]
  };
  var faWalking = {
    prefix: 'fal',
    iconName: 'walking',
    icon: [320, 512, [], "f554", "M305.5 247.3l-19.6-21.1-8.7-28.5c-7.9-26.1-23.3-47.8-43.2-62.8 19.3-13.4 32-35.7 32-60.9 0-40.8-33.2-74-74-74s-74 33.2-74 74c0 15.7 4.9 30.3 13.3 42.3-14.7 3.8-28.4 9.8-45.3 17.4l-8.6 3.8c-24 10.5-43.4 30.1-54.9 55.2l-11.8 28c-7.3 17.2-5.7 35.7 4.2 50.7C24.6 286 41.2 295 58.4 295c12.5 0 24-4.7 32.9-12.9.2 1.4.4 2.7.6 4.1l-14.2 38.9-16.3 44.6L14 422.1c-18.7 20.4-18.7 52.4 0 72.9 9.6 10.5 23.2 16.6 37.2 16.6 14 0 27.6-6 37.2-16.6l46.7-51.2c9.6-10.6 17-22.9 22-36.5l8.7-23.9 10.6 10.7c.5.5.8 1.3.9 1.9l15.6 74.2C198 494.5 219 512 242.8 512c4 0 8.1-.5 12.1-1.5 27.4-6.9 44-34.6 38-62.9l-15.6-74.1c-2.8-13.2-7.8-25.6-14.7-36.7 1.8.2 3.6.3 5.5.3 20.3 0 38.9-12.6 47.4-32.2 8.6-19.5 4.7-41.6-10-57.6zM192 32c23.2 0 42 18.8 42 42s-18.8 42-42 42-42-18.8-42-42 18.8-42 42-42zm-65 364.3c-3.5 9.7-8.8 18.4-15.6 25.9l-46.7 51.2c-3.7 4.1-8.6 6.2-13.6 6.2-4.9 0-9.8-2.1-13.6-6.2-7.5-8.2-7.5-21.5 0-29.7l46.7-51.2c3.1-3.4 5.5-7.4 7.1-11.8l16.3-44.6.1.1 1 1.2 29 29.3-10.7 29.6zm141.1-91.1c-4.4 0-9-1.8-13-6.2l-23.5-25.3c-5-5.4-8.8-12.1-11-19.4-11.4-37.5-11.5-39.9-17.5-48.5l-20.9 91.7 43 43.4c10.4 10.6 17.7 24.2 20.8 39.3l15.6 74.1c2.4 11.3-4 22.6-14.4 25.2-1.5.4-3 .5-4.4.5-9.4 0-16.7-7.6-18.6-16.3l-15.6-74.2c-1.4-6.9-4.7-13-9.5-17.8L136 307.8c-11.6-13.8-15.7-31.2-11.9-47.7l15.8-69.3c-9 3.2-18.1 7.3-35.4 15-8 3.5-14.5 10-18.3 18.4L75 250.8c-3.7 8.7-10.1 12.3-16.6 12.3-12.5 0-25.2-13.4-18.2-29.9L51.7 206c8.3-18.2 21.9-31.9 38.6-39.2 34.2-15.1 48.7-22.8 76.6-22.8 36.3 0 68.3 25.3 79.8 63l10.3 33.9c.3 1 .8 2 1.6 2.8L282 269c14.1 15.3.9 36.2-13.9 36.2z"]
  };

  exports.faArrowAltRight = faArrowAltRight;
  exports.faBook = faBook;
  exports.faCalendar = faCalendar;
  exports.faCamera = faCamera;
  exports.faCapsules = faCapsules;
  exports.faCircle = faCircle;
  exports.faDownload = faDownload;
  exports.faEllipsisHAlt = faEllipsisHAlt;
  exports.faEnvelope = faEnvelope;
  exports.faEuroSign = faEuroSign;
  exports.faExpand = faExpand;
  exports.faFileAlt = faFileAlt;
  exports.faFileMedical = faFileMedical;
  exports.faMicroscope = faMicroscope;
  exports.faPaperclip = faPaperclip;
  exports.faPhone = faPhone;
  exports.faPrescriptionBottle = faPrescriptionBottle;
  exports.faReceipt = faReceipt;
  exports.faRedo = faRedo;
  exports.faScalpel = faScalpel;
  exports.faStethoscope = faStethoscope;
  exports.faSyringe = faSyringe;
  exports.faThumbtack = faThumbtack;
  exports.faTimesCircle = faTimesCircle;
  exports.faUndo = faUndo;
  exports.faUserMd = faUserMd;
  exports.faUsers = faUsers;
  exports.faVideo = faVideo;
  exports.faWalking = faWalking;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
